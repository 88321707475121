import React from "react";

const ReadingTime = ({ content, minText }) => {
  const calculateReadingTime = () => {
    if (content) {
      const wordCount = countWords(content);
      const lowRate = 200; //reading speed as needed
      return Math.ceil(wordCount / lowRate);
    }
    return 0;
  };

  const countWords = (content) => {
    const cleanText = content.replace(/<[^>]+>/g, ''); // Remove HTML tags
    const words = cleanText.trim().split(/\s+/);
    return words.length;
  };
  
  const readingTime = calculateReadingTime();

  return (
    readingTime > 0 && (
      <span>
        {readingTime} {minText || (readingTime === 1 ? 'minute' : 'minutes')}
      </span>
    )
  );
};

export default ReadingTime;