import React from "react"
import { Helmet } from "react-helmet";
import { graphql } from 'gatsby'
import Layout from "../components/layout";
import SEO from "../components/seo";
import Breadcrumbs_Insights from "../components/Breadcrumbs/Breadcrumbs_Insights"
import ArticleBlock from "../components/ArticleBlock/ArticleBlock"
import NewsLetter from "../components/NewsLetter/NewsLetter"
import FilterBlock from "../components/FilterBlock/FilterBlock"
import AuthorBlock from "../components/Insights/AuthorBlock";
//import {InsightsDetails} from "../queries/common_use_query"
//import LoaderLogo from '../images/loader_logo_new.svg';
import "../scss/custom.scss"
import { removeDataAttributes } from "../comQueryStructure";

const InsightsTemplate = (props) => {

  var mypagedata = removeDataAttributes(props?.data?.glstrapi?.insights);

  var data = mypagedata[0]


  return (
    <>
      <Layout Layout={'Without_Banner'} classNames="insight_det Without_Banner" Pagename={props.GQLPage?.Pagename}>
        <SEO title={data && data.Meta_Title} description={data && data.Meta_Description} image={data && data?.Banner_Image?.url} />

        <Helmet />

        {
          data && <>

            <div className="page-content static-page">
              <Breadcrumbs_Insights page_name={data.Category} Heading={data.Title} location={props.location} />

              <ArticleBlock article_data={data} />
              <AuthorBlock author={data?.people} />
              <NewsLetter />
            </div>

            <FilterBlock insights_id={''} article_data={data}/>
          </>
        }

      </Layout>
    </>
  )
}

export const query = graphql`
query insightQuery ($url: String){
  glstrapi {
    insights(filters: {URL: {eq: $url}}) {
      data {
        id
        attributes {
          Title
          Meta_Title
          Meta_Description
          URL
          Category
          Date
          Tag
          Banner_Image{
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          Tile_Image {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          imagetransforms
          ggfx_results {
            data {
              attributes {
                src_cftle
                transforms 
              }
            }
          }
          Author
          Highlight_Content
          Embed_Code
          Embed_Video_URL
          Content
          Show_Newsletter
          Office {
            data {
              id
              attributes {
                Name
                Direct_Phone
                Image {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
              }
            }
          }
          people {
            data {
              id
              attributes {
                Name
                URL
                Phone
                Highlight_Text
                Designation
                Tile_Image {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
                ggfx_results {
                  data {
                    attributes {
                      src_cftle
                      transforms 
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`

export default InsightsTemplate